.ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1000;
    border-radius: 14px;
    padding: 0px 40px 40px 40px;
    width: 100%;
    max-width: 700px;

    p {
        margin-bottom: 20px;
    }

    // payment modal radio button
    .RadioButton {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            $radioButtonSize: 17px;
            width: $radioButtonSize;
            height: $radioButtonSize;
            margin: 0 11px 0 0;
        }

        label {
            width: 100%;
        }
    }

    .sizeSelector {
        top: 10px;
    }
}
