@import "../sass/variables";

.mainDiv {
   // height: 900px;
    max-width: 700px;
    margin: auto;
    padding-top: 30px;
}
#addproductbutton {
  margin: 25px 0px;
}

.cartitem {
  background-color: #f0f0f0;
  margin: 7px 0px;
  border-radius: 10px;
  height: 65px !important;

  .sizeSelector {
    top: 2px;
  }
}

.productsTitle {
  margin-bottom: 20px;
}

.goToCartButton {
  margin-top: 30px !important;
}

.productListing {
  width: 75%; 
  min-height: 60px !important;
}

.eventName {
  text-align: left !important;
  line-height: 1.2em;
}

.productListing.MuiButton-contained {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.itemSubDescription {
  color: red;
  font-size: .7em;
  font-style: italic;
  background-color: #ffffff;
  border-radius: 2px;
  margin-top: 2px;
}
.Mui-disabled .itemSubDescription {
  opacity: .2;
}
.eventName.cartEventName .itemSubDescription {
  background-color: transparent !important;
}

@mixin blur ($radius) {
	-webkit-filter: blur($radius+px); /* Chrome, Safari */
	-ms-filter: blur($radius+px); /* IE12? */
	filter: url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"blur\"><feGaussianBlur stdDeviation=\"#{$radius}\" /></filter></svg>#blur"); /* Firefox, Safari, Chrome*/
	filter: progid:DXImageTransform.Microsoft.Blur(Strength=$radius); /* IE9 */
}
.sold-out {
  color: rgba(0, 0, 0, 0.18) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  pointer-events: none !important;
  cursor: default !important;
}
.sold-out div {
  @include blur(1);
}
.sold-out::after {
  content: "SOLD OUT";
  float: left;
  font-size: 1.4em;
  color: black;
  font-weight: 900;
  text-align: center;
  position: absolute;
  top: 15;
  left: 0;
  width: 100%;
  z-index: 100;
}

.eventDate {
  font-size: 1.5em;
  font-weight: 700;
}


.shirtDropdown {
  max-height: 60px !important;
}

.sizeSelector {
  margin: 0px 10px !important;
  min-width: 70px;
  display: block;
  position: relative;
  max-height: 60px !important;
  top: 10px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 1100px;

  & img {
    height: 85px;
    margin-bottom: 100px;
  }

  svg {
    color: $red;
    margin-right: 27px;
  }
}

@media only screen and (min-width: 1022px) {
  .Header img {
    height: 120px;
    margin: 15px 0 0 27px;
  }
}