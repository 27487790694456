@import "../sass/variables";

.ImageUpload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 351px;

    .ImageUpload__header {
        text-align: center;
        margin-bottom: 50px;
        font-size: 14px;
    }

    .submitButton {
        text-align: center;
        width: 210px;
    }

    // width of toast message
    .Toastify__toast-container.Toastify__toast-container--top-center {
        width: 436px;
    }
}

.DragAndDropContainer {
    display: flex;
    margin: 0 20px;
    width: 95%;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .submitButton {
        svg {
            margin-left: 14px;
        }
    }
}

.DragAndDrop {
    text-align: center;
    padding-bottom: 93px;
    
    label {
        font-weight: bold;
        font-size: 18px;
    }
}

.DropzoneAreaContainer {

    // offset to indent arrow buttons
    $offset: 5px;
    position: relative;
    
    .previousButton,
    .nextButton {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        cursor: pointer;
        position: absolute;
        top: 47%;
        border: none;
        background-color: white;
        opacity: 90%;
    }

    .previousButton {
        left: $offset;
    }

    .nextButton {
        right: $offset;
    }

    .DropzoneArea {
        width: 250px;
        height: 250px;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-position: center;
        background-size: cover;
    }
}

.fileNameContainer {
    position: relative;
    .fileName {
        text-align: center;
        width: 100%;
    }
}

.fileNameTextContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;

    .fa-trash-can {
        color: $red;
        cursor: pointer;
        height: 22px;
    }
}

.imageLineItems {
    position: absolute;
    width: 100%;
    margin-top: 20px;
}

.upload_message {
    color: $green
}

@media only screen and (min-width: 1022px) {
    .DragAndDropContainer {
        justify-content: space-between;
        flex-direction: row;
    }

    .ImageUpload {
        max-width: 911px;
        margin: 28vh auto;

        // width of toast message
        .Toastify__toast-container.Toastify__toast-container--top-center {
            width: 680px;
        }
    }

    .ImageUploadContainer {
        height: 85vh;
        display: flex;
        align-items: center;
    }

    // stops filename from overlapping with label on mobile
    .fileName {
        position: absolute;
    }
}
