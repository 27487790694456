/* styling for check mailing address footer */
.CheckFooter {
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    span {
        text-align: left;
    }
}

/* mobile first styling for check footer */
@media only screen and (min-width: 770px) {
    /* change to single line */
    .CheckFooter {
        flex-direction: row;
        width: 100%;
        justify-content: center;

        /* add spaces between words */
        span {
            margin-right: 5px;

            /* add commas */
            &:nth-child(2):after,
            &:nth-child(3):after {
                content: ", ";
            }
        }
    }
}
