@import "../sass/variables";

.Payment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.checkOrCreditCard {
  margin-bottom: 15px;
}

/* Containers for check and credit card sections */
.checkOrCreditCard > .check,
.checkOrCreditCard > .creditCard {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

/* styles for radio button labels */
.checkOrCreditCard span,
.paymentOptions > span {
  font-size: 17px;
  margin: 0 10px;
}

/* check and credit card icons */
.checkOrCreditCard img {
  margin: 0;
  max-height: 30px;
  object-fit: contain;
  margin-right: 10px;
}

/* container of radio buttons for selecting payment */
.paymentSelection {
  /* smaller width helps items wrap better */
  width: 99%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* text for selecting payment */
.paymentSelection > span {
  margin: 0 20px 0 10px;
}

/* Payment buttons container */
.addAndRemovePaymentButtons {
  display: flex;
  justify-content: space-between;

  button {
    color: white;
    border: none;
    border-radius: 3px;
    padding: 6px 4px;
    font-weight: 100;
    height: 36px;
    width: 145px;
  }
}

.modalButton {
  color: black;
  border: none;
  border-radius: 3px;
  padding: 6px 16px;
  font-weight: 100;
  height: 36px;
  width: 145px;
  cursor: pointer;
}

/* Payment selection buttons */
.previousPayment,
.nextPayment {
  color: black;
}

.addPayment {
  background-color: $darkBlue;
  line-height: 11px;
}

.removePayment {
  background-color: $red;
}

.previousPayment,
.nextPayment {
  color: black !important;
}

/* styles for disabled buttons */
.previousPaymentDisabled,
.nextPaymentDisabled,
.submitButtonDisabled {
  background-color: hsl(0, 0%, 80%);
  cursor: not-allowed;
}

.previousPaymentDisabled:hover,
.nextPaymentDisabled:hover {
  opacity: 100%;
}

.addPayment,
.removePayment,
.previousPayment,
.nextPayment {
  cursor: pointer;
}

.addPayment:hover,
.removePayment:hover,
.previousPayment:hover,
.nextPayment:hover {
  opacity: 80%;
}

/* section for amount due text */
.formHeader {
  display: flex;
  justify-content: flex-end;
}

/* styles for safari */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .addPayment,
    .removePayment,
    .previousPayment,
    .nextPayment {
      font-weight: 400 !important;
      font-size: 12px;
    }
  }
}

/* styles for firefox */
@-moz-document url-prefix() {
  .addPayment,
  .removePayment,
  .previousPayment,
  .nextPayment {
    font-weight: 400 !important;
    font-size: 12px;
  }
}

/* styles for mobile */
@media only screen and (max-width: 677px) {
  /* section for amount due text */
  .formHeader {
    flex-direction: column;
    margin-bottom: 15px;
    justify-content: start;
  }
}

@media only screen and (max-width: 718px) {
  .addAndRemovePaymentButtons {
    flex-direction: column;
  }

  .addAndRemovePaymentButtons > button {
    width: 100%;
    margin-bottom: 15px;
  }
}