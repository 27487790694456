.amountDue {
    width: 100%;
}

.amountDue > p {
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
}
/* container for amount due section */
.paymentContainer__header {
    display: flex;
    flex-wrap: wrap-reverse;
 }
 
 .paymentContainer__header > img {
   margin: 0;
   width: 7%;
   object-fit: contain;
   object-position: bottom;
   min-width: 45.5px;
   height: 50.5px;
 }
 
 .paymentContainer__header > .visa {
   margin-right: 15px;
 }
 
 @media only screen and (min-width: 770px) {
   
   /* allows amount due section to be in upper right hand corner*/
   .paymentContainer__header {
       flex-wrap: unset;
   }
 }

@media only screen and (min-width: 770px) {
    /* allows amount due section to be in upper right hand corner*/
    .amountDue > p {
        text-align: right;
    }
}
