@import "../sass/variables";

.Portal__button {
    background-color: transparent;
    border: none;
    border-radius: 3px;
    color: $red;
    cursor: pointer;
    // height: 36px;
    margin-top: 30px;
    padding: 6px 16px;
    width: 20%;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    height: auto;
}

.Portal__button__disabled {
    pointer-events: none;
    color: $redLight;
}

// logout button
.logoutButton {
    background-color: #f4311f;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 25px;
    position: absolute;
    top: 15px;
    right: 27px;
    cursor: pointer;
    
    // logout button icon
    .fa-right-from-bracket {
        color: #fff;
    }
}

.Portal__button:hover {
    color: $red;
}

.Portal__button > span {
    color: black;
}

.Portal__button svg {
    height: 3em;
}

.Portal__button.payments {
    background-color: transparent;
}

.Portal__button.imageUpload {
    position: relative;

    .imageUploadLabel {
        position: absolute;
        text-transform: capitalize;
        top: 54px;
    }
}

.containerNoModules {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
}

.Portal .formContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

/* move house icon up */
.Portal .fa-house {
    position: relative;
    bottom: 4px;
}

/* move arrow icon up */
.Portal .fa-cloud-arrow-up {
    position: relative;
    bottom: 1px;
}

/* stop underline on hover of link tags */
a:hover {
    text-decoration: none;
}

@media only screen and (max-width: 535px) {
    .Portal__button > span {
        font-size: 10px;
    }
}